import { Storage } from "aws-amplify";

export const CloudfrontUrl = "https://dj565pehwnis1.cloudfront.net/public/";

export const BunketUrl =
  "https://dashboardloreale2758efaaec6433b8fc8e3a24604d2b0134054-dev.s3.ap-southeast-1.amazonaws.com/public/";
// dashboardloreale2758efaaec6433b8fc8e3a24604d2b0134054-dev
export const FunctionGetPathUrl = (key) => {
  if (key) {
    return `${CloudfrontUrl}${key}`;
  } else {
    return;
  }
};

export const FunctionGetImageFromS3 = (key) => {
  if (key) {
    return `${BunketUrl}${key}`;
  } else {
    return;
  }
};
export const FunctionAddUrlToFile = (file) => {
  return URL.createObjectURL(file);
};

export const FunctionUploadFileAws = async (file) => {
  if (file.size) {
    const keyUpload = `${new Date().getTime()}_${file?.name}`;
    await Storage.put(keyUpload, file);
    return keyUpload;
  } else {
    return file;
  }
};

export const FunctionDeletePath = (input) => {
  if (input) {
    const new_path = String(input).replace(BunketUrl, "");
    return new_path;
  } else {
    return;
  }
};

export const FunctionOpenInNewTab = (url) => {
  if (url) {
    window.open(url, "_blank");
  } else {
    return;
  }
};

export const FunctionMakeDoubleDigit = (input) => {
  return input < 10 ? "0" + input : input;
};

export const FunctionFormateTime = (year, month, day, hour, minute, second) => {
  return {
    day: FunctionMakeDoubleDigit(day),
    hour: FunctionMakeDoubleDigit(hour),
    minute: FunctionMakeDoubleDigit(minute),
    second: FunctionMakeDoubleDigit(second),
  };
};

export const FunctionGetTime = (input) => {
  var date = input;

  var year = new Date(date).getFullYear();
  var month = new Date(date).getMonth() + 1;
  var day = new Date(date).getDate() - new Date().getDate();

  var hour = new Date(date).getHours() - new Date().getHours();
  var minute = new Date(date).getMinutes() - new Date().getMinutes();
  var second = new Date(date).getSeconds() - new Date().getSeconds();

  return FunctionFormateTime(year, month, day, hour, minute, second);
};

export const FunctionZeroPad = (num, numZeros) => {
  var n = Math.abs(num);
  var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  var zeroString = Math.pow(10, zeros)
    .toString()
    .substr(1);
  if (num < 0) {
    zeroString = "-" + zeroString;
  }

  return zeroString + n;
};

export const FuntionFormatDate = (date_input = new Date()) => {
  const this_date = new Date(date_input);

  if (this_date) {
    const thai_date = this_date.toLocaleDateString("en-GB").split("/");
    const result = `${FunctionZeroPad(thai_date[0], 2)}/${FunctionZeroPad(
      thai_date[1],
      2
    )}/${thai_date[2]}`;

    return result;
  } else {
    return;
  }
};

export const FunctionRandomPassword = (length = 8) => {
  const thisRamdomPassword = Math.random()
    .toString(36)
    .slice(-length);

  const changeToUpperCasae = thisRamdomPassword.toUpperCase();

  return changeToUpperCasae;
};

export const FunctionDataUrlToFile = (dataurl, filename) => {
  if (dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename ? filename : "Filename", { type: mime });
  } else {
    return;
  }
};
