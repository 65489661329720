import React from "react";
import QRCode from "qrcode.react";
import classNames from "classnames";
// import {
//   FunctionDataUrlToFile,
//   FunctionUploadFileAws,
//   CloudfrontUrl,
// } from "./utils/useFunctions";
import { DATE_TO_JOIN_LIST } from "../utils/useConstants";

export const PressConference = ({ id, dateToJoin, size }) => {
  return (
    <React.Fragment className={`h-screen`}>
      <div
        className={`${
          size?.width > 2200 ? " text-3xl " : " text-2xl "
        }font-PSL242pro text-leoreal-blue font-normal mx-2 my-12 md:my-24 h-[50vh] text-center`}
      >
        {/* <div className="flex flex-col items-center">
          <span>ขอบคุณสำหรับการลงทะเบียนเข้าร่วมงาน</span>
          <span>Bridging Humanity and AI</span>
          <span>
            จะเป็นอย่างไรเมื่อปัญญามนุษย์ และปัญญาประดิษฐ์ร่วมกันสร้างสรรค์สื่อ
          </span>
          <div className="flex gap-2">
            <span>ในวันที่</span>
            <span className="font-semibold">
              {Object.keys(dateToJoin)?.map((key) => {
                if (!dateToJoin[key]) return null;
                return (
                  <div>
                    {
                      DATE_TO_JOIN_LIST.find(
                        (dateToJoinItem) => dateToJoinItem.value === key
                      ).label
                    }
                  </div>
                );
              })}
            </span>
          </div>
          <div className="flex gap-2">
            <span>เวลา</span>
            <span className="font-semibold">9.00 - 15.00 น.</span>
          </div>
        </div> */}
        <div className="flex flex-col items-center gap-5">
          <div className="font-semibold">
            ขอบคุณสำหรับการลงทะเบียนเข้าร่วมงาน
          </div>
          <div className="flex flex-col">
            <span>ท่านจะได้รับ QR Code ยืนยันสิทธิ์เข้าร่วมงาน</span>
            <span>ทางอีเมลตามที่ลงทะเบียนไว้</span>
            <span>ภายใน 7 วันทำการ</span>
          </div>
          <div className="flex flex-col">
            <span>หากไม่ได้รับอีเมล สามารถติดต่อได้ที่</span>
            <a href="info@thaimediafund-thinktank2.com">
              Email : info@thaimediafund-thinktank2.com
            </a>
            <span>โทร : 099-019-9360 คุณคิส</span>
          </div>
        </div>
      </div>

      {/* <div className="text-leoreal-blue text-base font-normal my-12 md:my-10 text-center  font-Essentielle_bold_Web">
        Confirmation ID : {id}
      </div> */}
      {/* <QRCode className=" mx-auto mt-6 md:mt-2 " value={id} renderAs="canvas" /> */}
      {/* <div className="text-leoreal-blue text-sm font-normal my-12 md:my-10 text-center  font-Essentielle_bold_Web">
        <div className="flex flex-col items-center">
          <span className="mb-5">
            กรุณาแสดง QR Code นี้ในการเข้าร่วมเสวนา และรับบัตรเข้างาน
          </span>
          <span>
            หากคุณต้องการเปลี่ยนวันเข้าร่วมเสวนา หรือยกเลิก กรุณาติดต่อเข้ามาที่
          </span>
          <span>Email : info@thaimediafund-thinktank2.com</span>
          <span>โทร : 099-019-9360 คุณคิส</span>
        </div>
      </div> */}
      {/* <div
        style={{
          fontFamily: "font-PSL242pro",
        }}
        className=""
      >
        QR Code ได้ส่งไปที่อีเมลของคุณเรียบร้อยแล้ว
        <div>กรุณาแสดง QR Code ที่จุดลงทะเบียน</div>
        <div>เพื่อ Check-in เข้าร่วมงาน</div>
      </div> */}
      {/* <img src="/images/textqrr.png" className="w-80 mx-auto my-6"></img> */}
    </React.Fragment>
  );
};

// export const CocktailReception = ({ id }) => {
//   return (
//     <React.Fragment>
//       <div className="text-leoreal-blue text-base font-semibold my-12 md:my-10 text-center  font-Essentielle_bold_Web">
//         CEREMONY & COCKTAIL RECEPTION
//         <div>L'ORÉAL FOR WOMEN IN SCIENCE 2022</div>
//       </div>
//       <div className="text-leoreal-blue text-base md:text-sm font-normal  text-center  -mt-10 md:-mt-6 font-Essentielle_bold_Web md:pb-6">
//         18.00 - 21.00 HRS.
//         <div>GRAND HALL, 2ⁿᵈ FLOOR</div>
//         <div>THE ATHENEE HOTEL BANGKOK</div>
//       </div>
//       <QRCode className="mx-auto mt-6 md:mt-2 " value={id} renderAs="canvas" />

//       <img src="/images/textqrr.png" className="w-80 mx-auto my-6"></img>
//     </React.Fragment>
//   );
// };
