import logo from "./logo.svg";
import "./App.css";
import RegisterMain from "./pages/Register.Main";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Amplify from "aws-amplify";
import {
  FunctionDataUrlToFile,
  FunctionUploadFileAws,
  CloudfrontUrl,
} from "./utils/useFunctions";
import React from "react";
Amplify.configure({
  Auth: {
    identityPoolId: "ap-southeast-1:459c7606-3a15-4c4f-b7b9-e3b856f5de0a",
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_gprlkp3aG",
    userPoolWebClientId: "op2897uarl6m84tsc53povaa3",
  },
  Storage: {
    bucket: "dashboardloreale2758efaaec6433b8fc8e3a24604d2b0134054-dev",
    region: "ap-southeast-1",
  },
});

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="/"
          element={<img src="l_oreal_20_years_ecard_02.jpg" alt="bg" />}
        /> */}
        {/* <Route path="/:type" element={<RegisterMain />} /> */}
        {/* <Route path="*" element={<div>img</div>} /> */}
        <Route path="/" element={<RegisterMain />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
