//
//
export const PREFIX_NAME_LIST = [
  { value: "man", label: "นาย" },
  { value: "unmarried_woman", label: "นางสาว" },
  { value: "woman", label: "นาง" },
];

export const TYPE_AGENCY_LIST = [
  { value: "content_creator", label: "คอนเทนครีเอเตอร์ / Content Creator" },
  { value: "mass_media", label: "สื่อมวลชน" },
  {
    value: "online_media",
    label: "สื่อออนไลน์ (สื่อมวลชน เน้นเฉพาะกลุ่ม Online)",
  },
  { value: "production_house", label: "Production House" },
  { value: "agency", label: "เอเจนซี่ / Agency" },
  { value: "writer", label: "นักเขียน" },
  { value: "student", label: "นักเรียน / นักศึกษา" },
  { value: "general_public", label: "บุคคลทั่วไป" },
  { value: "other_agency", label: "อื่น ๆ" },
];

export const FOOD_LIST = [
  {
    label: "ไม่แพ้อาหาร",
    value: "not_food_allergy",
  },
  {
    label: "แพ้อาหารทะเล",
    value: "seafood_allergy",
  },
  {
    label: "อิสลาม",
    value: "islam",
  },
  {
    label: "มังสวิรัติ",
    value: "vegetarian",
  },
  {
    label: "อื่น ๆ",
    value: "other_food",
  },
];

export const DATE_TO_JOIN_LIST = [
  {
    label: "12 ธันวาคม พ.ศ. 2566",
    value: "december_12_2023",
  },
  {
    label: "13 ธันวาคม พ.ศ. 2566",
    value: "december_13_2023",
  },
];
