import axios from "axios";

const dev = process.env.NODE_ENV === "development";

// https://z4apvhxvs5.execute-api.ap-southeast-1.amazonaws.com/dev

const instance = axios.create({
  baseURL: dev
    ? "http://localhost:1337"
    : "https://z4apvhxvs5.execute-api.ap-southeast-1.amazonaws.com/dev",
});

export default instance;
