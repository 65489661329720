import { FunctionOpenInNewTab } from "radium-omnibus";
import React from "react";

export const PDPAPressConference = ({ id }) => {
  return (
    <div className="p-1">
      <div className="underline font-bold text-gray-700 text-center mt-2">
        การยินยอมเปิดเผยข้อมูลส่วนบุคคล
      </div>
      <div className="mt-4 indent-5 md:indent-8">
        กองทุนพัฒนาสื่อปลอดภัยและสร้างสรรค์ จะดำเนินการเก็บรวบรวม ใช้ หรือ
        เปิดเผยข้อมูลส่วนบุคคล ซึ่งรวมถึง ชื่อ นามสกุล เบอร์โทรศัพท์ หน่วยงาน /
        สังกัด และอีเมลของท่าน{" "}
        <span className="font-semibold text-gray-600">
          เพื่อวัตถุประสงค์ในการเข้าร่วมงานเสวนางานวิชาการเชิงนวัตกรรม (Think
          Tank) เพื่อยกระดับการพัฒนาสื่อปลอดภัยและสร้างสรรค์ ปี 2 เรื่อง
          “Bridging Humanity and AI” จะเป็นอย่างไร
          เมื่อปัญญามนุษย์และปัญญาประดิษฐ์ร่วมมือกันสร้างสรรค์สื่อ
        </span>{" "}
        เท่านั้น โดยภายในงานจะมีการบันทึกภาพบรรยากาศกิจกรรมด้วย
      </div>
      <div className="mt-4 indent-5 md:indent-8">
        ทั้งนี้ ก่อนการแสดงเจตนา ข้าพเจ้าได้อ่านรายละเอียดจากเอกสารชี้แจงข้อมูล
        หรือได้รับคำอธิบายจากกองทุนพัฒนาสื่อปลอดภัยและสร้างสรรค์
        ถึงวัตถุประสงค์ในการเก็บรวบรวม ใช้หรือเปิดเผย (“ประมวลผล”)
        ข้อมูลส่วนบุคคล และมีความเข้าใจดีแล้ว
      </div>
      <div className="mt-4 indent-5 md:indent-8">
        ข้าพเจ้าให้ความยินยอม หรือ
        ปฏิเสธไม่ให้ความยินยอมในเอกสารนี้ด้วยความสมัครใจ
        ปราศจากการบังคับหรือชักจูง
        และข้าพเจ้าทราบว่าข้าพเจ้าสามารถถอนความยินยอมนี้เสียเมื่อใดก็ได้เว้นแต่ในกรณีมีข้อจำกัดสิทธิตามกฎหมายหรือยังมีสัญญาระหว่างข้าพเจ้ากับกองทุนพัฒนาสื่อปลอดภัยและสร้างสรรค์
        ที่ให้ประโยชน์แก่ข้าพเจ้าอยู่
      </div>
      <div className="mt-4 indent-5 md:indent-8">
        กรณีที่ข้าพเจ้าประสงค์จะขอถอนความยินยอม
        ข้าพเจ้าทราบว่าการถอนความยินยอมจะมีผลทำให้
        ข้าพเจ้าอาจได้รับความสะดวกในการใช้บริการน้อยลง หรือ
        ไม่สามารถเข้าถึงฟังก์ชันการใช้งานบางอย่างได้ เป็นต้น
        และข้าพเจ้าทราบว่าการถอนความยินยอมดังกล่าว
        ไม่มีผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลที่ได้ดำเนินการเสร็จสิ้นไปแล้วก่อนการถอนความยินยอม
      </div>
      {/* <div className="mt-4">
        ท่านสามารถศึกษาข้อมูลเพิ่มเติมเกี่ยวกับการใช้ข้อมูลส่วนบุคคลของท่านได้ที่
        <span
          onClick={(e) => {
            e.stopPropagation();
            FunctionOpenInNewTab(
              "https://www.loreal.com/en/thailand/pages/group/privacy-policy-thailand-th/"
            );
          }}
          className="underline text-blue-500 cursor-pointer"
        >
          {" "}
          นโยบายข้อมูลส่วนบุคคล
        </span>{" "}
        ของเรา{" "}
      </div> */}
    </div>
  );
};

// export const PDPACocktailReception = ({ id }) => {
//   return (
//     <div className="p-1">
//       <div className="underline font-bold text-center my-2">
//         ประกาศความเป็นส่วนตัว
//       </div>
//       <div className="">
//         บริษัท ลอรีอัล (ประเทศไทย) จำกัด
//         จะดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่าน ซึ่งรวมถึง ชื่อ นามสกุล
//         เบอร์โทรศัพท์ หน่วยงาน/สังกัด และอีเมลของท่าน
//         เพื่อวัตถุประสงค์ในการเข้าร่วมพิธีมอบทุนโครงการ “ทุนวิจัยลอรีอัล
//         ประเทศไทย เพื่อสตรีในงานวิทยาศาสตร์” ประจำปี 2565 เท่านั้น
//         โดยภายในงานจะมีการบันทึกภาพบรรยากาศภายในกิจกรรมด้วย
//       </div>
//       <div className="mt-4">
//         ท่านสามารถศึกษาข้อมูลเพิ่มเติมเกี่ยวกับการใช้ข้อมูลส่วนบุคคลของท่านได้ที่
//         <span
//           onClick={(e) => {
//             e.stopPropagation();
//             FunctionOpenInNewTab(
//               "https://www.loreal.com/en/thailand/pages/group/privacy-policy-thailand-th/"
//             );
//           }}
//           className="underline text-blue-500 cursor-pointer"
//         >
//           {" "}
//           นโยบายข้อมูลส่วนบุคคล
//         </span>{" "}
//         ของเรา{" "}
//       </div>
//     </div>
//   );
// };
