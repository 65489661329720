import React, { useEffect, useState } from "react";
import {
  ButtonSubmitController,
  Controllers,
  FunctionOpenInNewTab,
} from "radium-omnibus";
import { useWindowSize } from "../utils/useWindowSize";
import { useParams } from "react-router-dom";
import { PressConference } from "../components/time";
import { CocktailReception } from "../components/time";
import api from "../api";
import QRCode from "qrcode.react";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";
import Select from "react-select";

import {
  FunctionDataUrlToFile,
  FunctionGetPathUrl,
} from "../utils/useFunctions";

import { CloudfrontUrl } from "../utils/useFunctions";

import { FunctionUploadFileAws } from "../utils/useFunctions";
import { PDPACocktailReception, PDPAPressConference } from "../components/pdpa";
import { type } from "@testing-library/user-event/dist/type";

import {
  PREFIX_NAME_LIST,
  TYPE_AGENCY_LIST,
  FOOD_LIST,
  DATE_TO_JOIN_LIST,
} from "../utils/useConstants";
import _ from "lodash";

const STATE_CONSTANT_REGISTER = {
  CURRENT: "CURRENT",
  PREVIEW: "PREVIEW",
  QR: "QR",
};

const MAX_DATE = 80;

const RenderModalPDPA = ({ id, params }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <React.Fragment>
        {/* {params?.type === "presscon" ? ( */}
        <div>
          <PDPAPressConference />
        </div>
        {/* ) : (
          <PDPACocktailReception />
        )} */}
      </React.Fragment>

      {/* <div
        onClick={() => {
          setOpen(false);
        }}
        style={{
          opacity: open ? 1 : 0,
        }}
        className={` ${
          open
            ? " w-full h-80 top-10 left-0 p-10 shadow-xl font-PSL242pro text-base  "
            : " w-0 h-0 top-72 left-60 p-0 shadow-none"
        } transition-all absolute  bg-white border border-gray-700 font-PSL242pro text-base mt-64 md:mt-0`}
      > */}
      {/* <div className="underline font-bold text-center -my-3">
          ประกาศความเป็นส่วนตัว
        </div> */}

      {/* <div className="mt-8">
          ท่านสามารถศึกษาข้อมูลเพิ่มเติมเกี่ยวกับการใช้ข้อมูลส่วนบุคคลของท่านได้ที่
          <span
            onClick={(e) => {
              e.stopPropagation();
              FunctionOpenInNewTab(
                "https://www.loreal.com/en/thailand/pages/group/privacy-policy-thailand-th/"
              );
            }}
            className="underline text-blue-500 cursor-pointer"
          >
            {" "}
            นโยบายข้อมูลส่วนบุคคล
          </span>{" "}
          ของเรา{" "}
        </div> */}
      {/* </div> */}
    </React.Fragment>
  );
};

const Finish = ({ size, id, params, dateToJoin }) => {
  return (
    <div id="container_register">
      <Container
        // height="520px"
        // width="450px"
        // width={size?.isMobile ? "375px" : "450px"}
        // header={
        //   <div className="relative ">
        //     <div
        //       className={`${
        //         size?.width > 2200 ? " mx-40" : "mx-2 md:mx-14 "
        //       } absolute bg-leoreal-blue w-80 h-auto  -my-8 md:-my-10 rounded-md`}
        //       style={{
        //         fontFamily: "PSL242pro",
        //       }}
        //     >
        //       <div className=" text-white text-center text-2xl ">
        //         <div>คุณได้ทำการลงทะเบียนเรียบร้อยแล้ว</div>
        //         <div>ขอบคุณสำหรับการลงทะเบียนเข้าร่วมงาน</div>
        //       </div>
        //     </div>
        //   </div>
        // }
        body={
          <React.Fragment>
            {/* {params?.type === "presscon" ? ( */}
            <div>
              <PressConference id={id} dateToJoin={dateToJoin} size={size} />
            </div>
            {/* ) : (
               <CocktailReception id={id} />
            )} */}
          </React.Fragment>
        }
      />
    </div>
  );
};

const Container = ({ body, header, height, width, footer }) => {
  const size = useWindowSize();

  // console.log("size : ", size);

  return (
    <div className="h-full ">
      <div
        id="bg-ja"
        className={
          size?.isMobile
            ? "pt-[335px] pb-5 relative h-full bg-blue-900"
            : "relative overflow-y-auto"
        }
        style={
          size?.isMobile
            ? {
                // height: `${size?.height}px`,
                backgroundImage: `url(/images/bgmobile.png)`,
                backgroundSize: `100% ${size?.height}px`,
                backgroundRepeat: "no-repeat",
              }
            : {
                height: `${size?.height}px`,
                backgroundImage: `url(/images/bgfulldesktop.png)`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }
        }
      >
        {/* {size.isMobile && (
          <img className="w-full md:w-full" src="/images/lorealmb.png" />
        )} */}
        <div
          style={
            size?.isMobile
              ? {}
              : {
                  position: "absolute",
                  left: `${
                    size?.width <= 820
                      ? "22.8%"
                      : size?.width <= 1024
                      ? "27%"
                      : size?.width > 1800
                      ? "65%"
                      : "60%"
                  } `,
                }
          }
          className="md:py-10"
          // className={`${
          //   size.isMobile ? "" : " top-1/2 transform   -translate-y-1/2  "
          // }`}
        >
          <div
            // style={{
            //   height: height,
            //   width: width,
            // }}
            // className={`${
            //   size.isMobile ? "-mt-1 md:mt-6 " : ""
            // } bg-leoreal-login rounded-md  text-3xl text-leoreal-text p-5 w-auto md:w-full h-full mx-auto md:-mb-16`}
            style={{
              width: `${
                size.isMobile ? "375px" : size?.width > 2200 ? "680px" : "480px"
              }`,

              // height: `${size.height * 0.6}px`,
              height: `${
                size.isMobile
                  ? ""
                  : size?.width > 2200
                  ? `${size?.height * 2}px`
                  : height
              }`,
            }}
            className={`${
              size.isMobile ? "-mt-1 md:mt-6" : ""
            } bg-leoreal-login rounded-md  text-3xl text-leoreal-text p-5 mx-auto `}
          >
            {/* <div className="bg-leoreal-blue w-72 h-auto mx-auto -my-8 px-1 rounded-md"> */}

            {header}
            {body}
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

const RegisterMain = () => {
  const [state, setState] = React.useState(STATE_CONSTANT_REGISTER.CURRENT);
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [id, setId] = useState("");
  const [qr, setQr] = useState(``);
  const [agreement, setAgreeMent] = useState("");
  // const params = useParams();
  const params = { type: "presscon" };

  const [prefixName, setPrefixName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [typeAgency, setTypeAgency] = useState("");
  const [agency, setAgency] = useState("");
  const [otherAgencyName, setOtherAgencyName] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [food, setFood] = useState({
    not_food_allergy: false,
    seafood_allergy: false,
    islam: false,
    vegetarian: false,
    other_food: false,
  });
  const [otherFoodName, setOtherFoodName] = useState("");
  const [dateToJoin, setDateToJoin] = useState({
    december_12_2023: false,
    december_13_2023: false,
  });

  const [countUser, setCountUser] = useState({
    december_12_2023: 0,
    december_13_2023: 0,
  });

  useEffect(() => {
    const loadCountUser = async () => {
      try {
        const { data } = await api.get(`/profiles/validateDateJoin`);
        setCountUser({
          december_12_2023: data?.count12,
          december_13_2023: data?.count13,
        });
      } catch (error) {
        console.log("error send email", error);
      }
    };
    loadCountUser();
  }, []);

  const handleOnChange = (newFood) => {
    setFood((prev) => {
      let temp = { ...prev };
      temp[newFood.value] = !temp[newFood.value];
      return temp;
    });
  };

  const onChangeDateToJoin = (newDateToJoin) => {
    setDateToJoin((prev) => {
      let temp = { ...prev };
      temp[newDateToJoin.value] = !temp[newDateToJoin.value];
      return temp;
    });
  };

  const STYLE_CLASSNAME_WRAPPER_INPUT = `${
    size?.width > 2200 ? " h-16 " : " h-12 "
  } bg-white rounded-md  mx-2 md:mx-auto`;

  const STYPE_TEXT_INPUT = ` ${
    size?.width > 2200 ? " text-3xl " : " text-xl "
  } font-PSL242pro text-leoreal-text rounded-md border border-leoreal-bordertwo  w-full h-full  px-4  focus:outline-none `;
  // useEffect(() => {
  //     setTimeout(async () => {
  //     const qr = await FunctionGenerateQrCode();
  //       setYourQr(qr);
  //     }, [3000]))

  useEffect(() => {
    // console.log("ID ", id);

    if (String(id).length === 24) {
      genQRCode(id);
    } else {
      return;
    }
  }, [id]);

  const convertDateToJoin = () => {
    if (dateToJoin.december_12_2023 && dateToJoin.december_13_2023) {
      return "12 ธันวาคม พ.ศ. 2566 และ 13 ธันวาคม พ.ศ. 2566";
    } else if (dateToJoin.december_12_2023) {
      return "12 ธันวาคม พ.ศ. 2566";
    }
    return "13 ธันวาคม พ.ศ. 2566";
  };

  // const FunctionSendEmail = async () => {
  //   try {
  //     if (qr) {
  //       await api.put(`/profiles/${id}`, {
  //         qr_code: qr,
  //       });
  //       // const elementFinish = Finish({ size, id, params });
  //       await api.post("/sendemailLoreal", {
  //         send_to: email,
  //         firstName: firstName,
  //         lastName: lastName,
  //         dateToJoin: convertDateToJoin(),
  //         qr_id: id,
  //         src: FunctionGetPathUrl(qr),
  //         // start_time: params.type === "presscon" ? "09.30 " : "18.00",
  //         // end_time: params.type === "presscon" ? "13.00" : "21.00",
  //         // title:
  //         //   params.type === "presscon"
  //         //     ? "PRESS CONFERENCE "
  //         //     : "CEREMONY & COCKTAIL RECEPTION",
  //         // html: ReactDOMServer.renderToString(elementFinish),
  //         // html:
  //         // send_to: form?.email,
  //         // password: thisRamdomPassword,
  //         // first_name: form?.first_name,
  //         // last_name: form?.last_name,
  //         // middle_name: form?.middle_name,
  //         // title: form?.title,
  //         // src: yourQr,
  //       });
  //       setState(STATE_CONSTANT_REGISTER.QR);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log("error send email", error);
  //   }
  // };

  // React.useEffect(() => {
  //   FunctionSendEmail();
  // }, [qr]);

  const genQRCode = async (id) => {
    let qr_code = await FunctionGenerateQrCode();
    await api.put(`/profiles/${id}`, {
      qr_code: qr_code,
    });
    setQr(qr_code);
    setState(STATE_CONSTANT_REGISTER.QR);
  };

  const onClickSubmit = async (form) => {
    try {
      setLoading(true);
      // console.log("form ;", form);

      const payload = {
        prefix_name: prefixName?.value,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        typeAgency: typeAgency?.value,
        agency: agency,
        jobPosition: jobPosition,
        food: {
          not_food_allergy: food?.not_food_allergy,
          seafood_allergy: food?.seafood_allergy,
          islam: food?.islam,
          vegetarian: food?.vegetarian,
          other_food: food?.other_food,
        },
        // other_food_name: otherFoodName,
        date_to_join: {
          december_12_2023: dateToJoin?.december_12_2023,
          december_13_2023: dateToJoin?.december_13_2023,
        },
      };

      if (typeAgency?.value === "other_agency") {
        payload.other_agency_name = otherAgencyName;
      }

      if (food?.other_food === true) {
        payload.other_food_name = otherFoodName;
      }

      const data = await api.post("/profiles", {
        ...payload,
        // register_type: params?.type === "presscon" ? "M" : "A",
      });
      setId(data?.data._id);
    } catch (error) {
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const FunctionGenerateQrCode = async () => {
    var filter = Array.prototype.filter,
      result = document.querySelectorAll("canvas"),
      filtered = filter.call(result, function (node) {
        return node.height === node.width;
      });

    if (filtered?.length > 0) {
      const thisDataUrl = filtered[0]?.toDataURL("image/png");

      const thisQrCodeFile = FunctionDataUrlToFile(
        thisDataUrl,
        `${`qr${new Date().getTime()}.png`}`
      );
      const thisKey = await FunctionUploadFileAws(thisQrCodeFile);
      const thisSrc = thisKey;
      return thisSrc;
    } else {
      return;
    }
  };

  // const onChangeForm = (e) => {
  //   const { name, value } = e.target;
  //   setForm((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // };

  // console.log("form", form);

  // const [stylePre , setStylePre]= useState(false)

  // if (!["ceremony", "presscon"].includes(params?.type))
  //   return (
  //     <div>
  //       <img src="l_oreal_20_years_ecard_02.jpg" alt="bg" />
  //     </div>
  //   );

  if (state === STATE_CONSTANT_REGISTER.CURRENT) {
    return (
      <Container
        // height="550px"
        // width="450px"
        // width={size?.isMobile ? "375px" : "450px"}
        body={
          <div className="my-6 md:my-12 space-y-4 font-PSL242pro">
            {/* <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
              <input
                name="first_name"
                onChange={onChangeForm}
                value={form.name}
                className={STYPE_TEXT_INPUT}
              />
            </div> */}

            <Controllers
              loadData={{ ...form }}
              showError={false}
              showSymbol={false}
              fields={[
                {
                  // classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                  type: "none",
                  name: "prefixName",
                  // classNameInput: STYPE_TEXT_INPUT,
                  render: () => {
                    return (
                      <Select
                        id="prefixName"
                        options={PREFIX_NAME_LIST}
                        placeholder="นาย / นางสาว / นาง"
                        className={` ${
                          size?.width > 2200 ? " text-3xl " : " text-xl "
                        } font-PSL242pro text-leoreal-text bg-white mx-2 md:mx-auto`}
                        value={prefixName}
                        onChange={(objOfvalue) => setPrefixName(objOfvalue)}
                        isSearchable={false}
                      />
                    );
                  },
                },
                // {
                //   classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                //   type: "text",
                //   name: "firstName",
                //   placeholder: "ชื่อ",
                //   required: true,
                //   classNameInput: STYPE_TEXT_INPUT,
                // },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="firstName"
                          type="text"
                          name="firstName"
                          placeholder="ชื่อ"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className={STYPE_TEXT_INPUT}
                        />
                      </div>
                    );
                  },
                },
                // {
                //   classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                //   type: "text",
                //   name: "lastName",
                //   placeholder: "สกุล",
                //   required: true,
                //   classNameInput: STYPE_TEXT_INPUT,
                // },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="lastName"
                          type="text"
                          name="lastName"
                          placeholder="นามสกุล"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className={STYPE_TEXT_INPUT}
                        />
                      </div>
                    );
                  },
                },
                // {
                //   classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                //   type: "text",
                //   name: "phoneNumber",
                //   placeholder: "เบอร์โทร",
                //   required: true,
                //   classNameInput: STYPE_TEXT_INPUT,
                // },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="phoneNumber"
                          type="number"
                          name="phoneNumber"
                          placeholder="เบอร์โทร"
                          required
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value.slice(0, 10));
                          }}
                          className={STYPE_TEXT_INPUT}
                          onWheel={(e) => e?.target?.blur()}
                        />
                      </div>
                    );
                  },
                },
                // {
                //   classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                //   type: "email",
                //   name: "email",
                //   placeholder: "อีเมล",
                //   required: true,
                //   classNameInput: STYPE_TEXT_INPUT,
                // },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          placeholder="อีเมล"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className={STYPE_TEXT_INPUT}
                        />
                      </div>
                    );
                  },
                },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="email2"
                          type="email2"
                          name="email2"
                          placeholder="ยืนยันอีเมลอีกครั้ง"
                          required
                          value={email2}
                          onChange={(e) => setEmail2(e.target.value)}
                          className={STYPE_TEXT_INPUT}
                        />
                      </div>
                    );
                  },
                },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div
                        className={` ${
                          size?.isMobile
                            ? "mx-2 text-sm"
                            : size?.width > 2200
                            ? " text-2xl "
                            : " text-lg"
                        } font-PSL242pro text-red-600 -mt-4 -mb-2 px-4 focus:outline-none `}
                      >
                        *QR Code
                        สำหรับเข้างานจะถูกส่งไปยังอีเมลที่ท่านลงทะเบียนไว้
                      </div>
                    );
                  },
                },
                {
                  // classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                  type: "none",
                  name: "typeAgency",
                  // classNameInput: STYPE_TEXT_INPUT,
                  render: () => {
                    return (
                      <div
                        className={`${
                          size?.isMobile ? "mx-2" : ""
                        } flex flex-col gap-1.5 bg-white rounded-b-md`}
                      >
                        <Select
                          id="typeAgency"
                          options={TYPE_AGENCY_LIST}
                          placeholder="ประเภทหน่วยงาน"
                          className={` ${
                            size?.width > 2200 ? " text-3xl " : " text-xl "
                          } font-PSL242pro text-leoreal-text bg-white`}
                          value={typeAgency}
                          onChange={(objOfvalue) => {
                            setTypeAgency(objOfvalue);
                            setOtherAgencyName("");
                          }}
                          isSearchable={false}
                        />
                        {typeAgency?.value === "other_agency" ? (
                          <>
                            <div
                              className={` ${
                                size?.width > 2200 ? " text-3xl " : " text-xl "
                              } font-PSL242pro text-red-600 -mb-2.5 px-4  focus:outline-none `}
                            >
                              *กรุณาระบุ
                            </div>
                            <div
                              className={`${
                                size?.width > 2200 ? " h-16 " : " h-12 "
                              }bg-white m-2`}
                            >
                              <input
                                id="otheragencyname"
                                type="text"
                                name="otheragencyname"
                                placeholder="กรุณาระบุ ..."
                                required
                                value={otherAgencyName}
                                onChange={(e) =>
                                  setOtherAgencyName(e.target.value)
                                }
                                className={STYPE_TEXT_INPUT}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  },
                },
                // {
                //   classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                //   type: "text",
                //   name: "agency",
                //   placeholder: "ชื่อหน่วยงาน",
                //   required: true,
                //   classNameInput: STYPE_TEXT_INPUT,
                // },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="agency"
                          type="text"
                          name="agency"
                          placeholder="ชื่อหน่วยงาน"
                          required
                          value={agency}
                          onChange={(e) => setAgency(e.target.value)}
                          className={STYPE_TEXT_INPUT}
                        />
                      </div>
                    );
                  },
                },
                // {
                //   classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                //   type: "text",
                //   name: "jobPosition",
                //   placeholder: "ตำแหน่ง",
                //   required: true,
                //   classNameInput: STYPE_TEXT_INPUT,
                // },
                {
                  type: "none",
                  render: () => {
                    return (
                      <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                        <input
                          id="jobPosition"
                          type="text"
                          name="jobPosition"
                          placeholder="ตำแหน่ง"
                          required
                          value={jobPosition}
                          onChange={(e) => setJobPosition(e.target.value)}
                          className={STYPE_TEXT_INPUT}
                        />
                      </div>
                    );
                  },
                },
                {
                  // classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                  type: "none",
                  name: "food",
                  // classNameInput: STYPE_TEXT_INPUT,
                  render: () => {
                    return (
                      <div
                        className={`${
                          size?.width > 2200 ? " text-3xl " : " text-xl "
                        } font-PSL242pro text-leoreal-text bg-white mx-2 md:mx-auto p-2.5 rounded-md border border-leoreal-bordertwo`}
                      >
                        <div>อาหาร</div>
                        {FOOD_LIST?.map((el, index) => {
                          return (
                            <div className="flex gap-2.5">
                              <input
                                key={index}
                                type="checkbox"
                                id="food"
                                name="food"
                                checked={food[el.value]}
                                onChange={() => handleOnChange(el)}
                              />
                              <div>{el.label}</div>
                            </div>
                          );
                        })}
                        {food?.other_food === true ? (
                          <>
                            <div className="text-red-600">*กรุณาระบุ</div>
                            <div className={STYLE_CLASSNAME_WRAPPER_INPUT}>
                              <input
                                type="text"
                                name="otherfood"
                                placeholder="กรุณาระบุ ..."
                                className={STYPE_TEXT_INPUT}
                                value={otherFoodName}
                                onChange={(e) =>
                                  setOtherFoodName(e.target.value)
                                }
                              />
                            </div>
                          </>
                        ) : (
                          setOtherFoodName("")
                        )}
                      </div>
                    );
                  },
                },
                {
                  // classNameWrapper: STYLE_CLASSNAME_WRAPPER_INPUT,
                  type: "none",
                  name: "dateToJoin",
                  // classNameInput: STYPE_TEXT_INPUT,
                  render: () => {
                    return (
                      <div
                        className={`${
                          size?.width > 2200 ? " text-3xl " : " text-xl "
                        } font-PSL242pro text-leoreal-text bg-white mx-2 md:mx-auto p-2.5 rounded-md border border-leoreal-bordertwo`}
                      >
                        <div className="flex flex-col md:flex-row md:gap-2">
                          <span>วันที่ต้องการเข้าร่วมงาน</span>
                          <span className="text-red-600 text-lg italic">
                            *สามารถเข้าร่วมได้มากกว่า 1 วัน
                          </span>
                        </div>
                        {DATE_TO_JOIN_LIST?.map((el, index) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center gap-2.5"
                            >
                              <input
                                key={index}
                                type="checkbox"
                                id="dateToJoin"
                                name="dateToJoin"
                                disabled={countUser[el.value] >= MAX_DATE}
                                checked={dateToJoin[el.value]}
                                onChange={() => onChangeDateToJoin(el)}
                              />
                              <div className="flex gap-2">
                                <span>{el.label}</span>

                                {countUser[el.value] >= MAX_DATE ? (
                                  <span className="text-red-600 italic">
                                    เต็ม
                                  </span>
                                ) : (
                                  <span className="text-gray-400">
                                    (รับเพียง 50 ท่านเท่านั้น)
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        {/* </div> */}
                      </div>
                    );
                  },
                },

                {
                  type: "none",
                  render: () => {
                    return (
                      <div
                        className={`font-PSL242pro text-2xl mt-12 -mb-8 px-3 flex justify-center mx-auto`}
                      >
                        <div className="flex flex-col items-center">
                          <div>สามารถรับชมงานเสวนาผ่านการถ่ายทอดสดได้ที่</div>
                          <div>Facebook : กองทุนสื่อปลอดภัยและสร้างสรรค์</div>
                          <a
                            href="https://www.facebook.com/ThaiMediaFundOfficial"
                            className={`${
                              size.isMobile ? "text-[22px]" : ""
                            } text-blue-500 underline`}
                          >
                            https://www.facebook.com/ThaiMediaFundOfficial
                          </a>
                          {size.isMobile ? (
                            <div className="flex flex-col items-center">
                              <div>ในวันที่ 12 ธันวาคม พ.ศ. 2566</div>
                              <div>และ 13 ธันวาคม พ.ศ. 2566</div>
                            </div>
                          ) : (
                            <div>
                              ในวันที่ 12 ธันวาคม พ.ศ. 2566 และ 13 ธันวาคม พ.ศ.
                              2566
                            </div>
                          )}

                          <div>เวลา 09.00 น. - 15.00 น.</div>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              // ดักลงทะเบียนซ้ำ
              onSubmit={async (e) => {
                if (!prefixName) {
                  alert("กรุณาระบุคำนำหน้าชื่อ นาย/นางสาว/นาง ...");
                  document.getElementById("prefixName").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                if (!firstName) {
                  alert("กรุณาระบุชื่อ ...");
                  document.getElementById("firstName").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                } else if (firstName) {
                  const regexFirstName = /^[A-Za-zก-ฮะ-๙]+$/u;
                  const checkValidateFirstName = regexFirstName.test(firstName);
                  if (!checkValidateFirstName) {
                    alert(
                      "กรุณาระบุชื่อให้ถูกต้อง (ห้ามตัวเลข / ห้ามอักษรพิเศษ) ..."
                    );
                    document.getElementById("firstName").scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    return;
                  }
                }

                if (!lastName) {
                  alert("กรุณาระบุนามสกุล ...");
                  document.getElementById("lastName").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                } else if (lastName) {
                  const regexLastName = /^[A-Za-zก-ฮะ-๙]+$/u;
                  const checkValidateLastName = regexLastName.test(lastName);
                  if (!checkValidateLastName) {
                    alert(
                      "กรุณาระบุนามสกุลให้ถูกต้อง (ห้ามตัวเลข / ห้ามอักษรพิเศษ) ..."
                    );
                    document.getElementById("lastName").scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    return;
                  }
                }

                if (!phoneNumber) {
                  alert("กรุณาระบุเบอร์โทร ...");
                  document.getElementById("phoneNumber").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                if (!email) {
                  alert("กรุณาระบุอีเมล ...");
                  document.getElementById("email").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                } else if (email) {
                  const regexEmail =
                    /^\s*(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])\s*$/i;
                  const checkValidateEmail = regexEmail.test(email);
                  if (!checkValidateEmail) {
                    alert(
                      "กรุณากรอกอีเมลให้ถูกต้อง (ห้ามเว้นวรรค / ห้ามภาษาไทย) ..."
                    );
                    document.getElementById("email").scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    return;
                  }
                }

                if (!email2) {
                  alert("กรุณาระบุอีเมลยืนยันอีกครั้ง ...");
                  document.getElementById("email2").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                } else if (email !== email2) {
                  alert("กรุณาระบุอีเมลให้เหมือนกัน ...");
                  document.getElementById("email").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                if (
                  !typeAgency ||
                  (typeAgency?.value === "other_agency" && !otherAgencyName)
                ) {
                  alert("กรุณาระบุประเภทหน่วยงาน ...");
                  document.getElementById("typeAgency").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                if (!agency) {
                  alert("กรุณาระบุชื่อหน่วยงาน ...");
                  document.getElementById("agency").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                if (!jobPosition) {
                  alert("กรุณาระบุตำแหน่ง ...");
                  document.getElementById("jobPosition").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                if (Object.keys(food).every((key) => !food[key])) {
                  alert("กรุณาระบุอาหาร ...");
                  document.getElementById("food").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                } else if (food?.other_food === true && !otherFoodName) {
                  alert("กรุณาระบุอาหารอื่น ๆ ...");
                  document.getElementById("food").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                const { data } = await api.get(`/profiles/validateDateJoin`);
                const currentCountUser = {
                  december_12_2023: data?.count12,
                  december_13_2023: data?.count13,
                };

                if (
                  currentCountUser.december_12_2023 >= MAX_DATE &&
                  dateToJoin.december_12_2023
                ) {
                  alert("วันที่นี้รับสมัครสมาชิกเต็มแล้ว ...");
                  return;
                }

                if (
                  currentCountUser.december_13_2023 >= MAX_DATE &&
                  dateToJoin.december_13_2023
                ) {
                  alert("วันที่นี้รับสมัครสมาชิกเต็มแล้ว ...");
                  return;
                }

                if (Object.keys(dateToJoin).every((key) => !dateToJoin[key])) {
                  if (
                    currentCountUser.december_12_2023 >= MAX_DATE &&
                    currentCountUser.december_13_2023 >= MAX_DATE
                  ) {
                    alert("สมาชิกเต็มแล้ว ...");
                  } else {
                    alert("กรุณาเลือกวันที่จะเข้าร่วมงาน ...");
                  }
                  document.getElementById("dateToJoin").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  return;
                }

                // // const type = params.type === "presscon" ? "M" : "A";

                // // const {
                // //   data: { profiles },
                // // } = await api.get(
                // //   `/profiles?firstName=${e.firstName}&lastName=${e.lastName}&register_type=${type}`
                // //   //   &typeM=${e.typeM}&typeA=${e.typeA}
                // // );

                const {
                  data: { profiles },
                } = await api.get(
                  `/profiles?firstName=${firstName}&lastName=${lastName}`
                );

                if (profiles?.length > 0) {
                  alert(
                    "ชื่อ - นามสกุลนี้ได้ทำการลงทะเบียนเข้าร่วมงาน Bridging Humanity and AI แล้ว โปรดตรวจสอบอีเมลที่ได้ทำการลงทะเบียนไว้ หรือติดต่อ Email : info@thaimediafund-thinktank2.com โทร : 099-019-9360 คุณคิส"
                  );
                  return;
                }

                // // const {
                // //   data: { profiles: checkEmail },
                // // } = await api.get(
                // //   `/profiles?email=${e.email}&register_type=${type}`
                // // );

                const {
                  data: { profiles: checkEmail },
                } = await api.get(`/profiles?email=${email}`);

                if (checkEmail?.length > 0) {
                  alert(
                    "อีเมลนี้ได้ทำการลงทะเบียนเข้าร่วมงาน Bridging Humanity and AI แล้ว โปรดตรวจสอบอีเมลที่ได้ทำการลงทะเบียนไว้ หรือติดต่อ Email : info@thaimediafund-thinktank2.com โทร : 099-019-9360 คุณคิส"
                  );
                  return;
                }

                setForm(e);
                setState(STATE_CONSTANT_REGISTER.PREVIEW);
              }}
              classNameSubmit=" bg-leoreal-blue rounded-md mx-auto  flex justify-center mt-14  cursor-pointer"
              labelSubmit={
                <div
                  className={`${
                    size?.width > 2200 ? " text-3xl " : " text-2xl "
                  } font-PSL242pro  text-white  text-center  px-10 py-0 flex justify-center mx-auto`}
                >
                  ลงทะเบียน
                </div>
              }
            />
          </div>
        }
        header={
          <div className={`relative mx-0 md:mx-5`}>
            <div
              className={`${
                size?.width > 2200 ? " text-3xl " : " text-2xl "
              } text-leoreal-blue font-PSL242pro text-center p-0 md:p-3 flex flex-col`}
            >
              <span>ลงทะเบียนงาน</span>
              <span className="text-3xl">Bridging Humanity and AI</span>
              <span className="text-3xl">จะเป็นอย่างไรเมื่อปัญญามนุษย์</span>
              <span className="text-3xl">
                และปัญญาประดิษฐ์ร่วมกันสร้างสรรค์สื่อ
              </span>
              <span>วันที่ 12 - 13 ธันวาคม 2566</span>
              <span>โรงแรมโซฟิเทล กรุงเทพ สุขุมวิท</span>
              <span>ห้อง Ballroom 1 ชั้น 7</span>
            </div>

            {size?.isMobile ? (
              <div className="my-5">
                <img src="/images/agendamobile.png" className="w-full h-auto" />
              </div>
            ) : (
              <div className="-mx-5 my-5">
                <img
                  src="/images/agendadesktop.png"
                  className="w-full h-auto"
                />
              </div>
            )}

            {/* <div
              className={`${
                size?.width > 2200 ? " w-60 " : " w-52 "
              } absolute bg-leoreal-blue h-auto  left-1/2 transform -translate-x-1/2  -my-8 md:-my-9 rounded-md`}
            >
              <div
                className={`${
                  size?.width > 2200 ? " text-3xl " : " text-2xl "
                } text-white font-PSL242pro text-center p-2 md:p-3 `}
              >
                ลงทะเบียนเพื่อเข้าร่วมงาน
              </div>
            </div> */}
          </div>
        }
        footer={
          <div
            className={`${
              size?.width > 2200 ? " text-3xl " : " text-xl "
            } text-leoreal-text font-PSL242pro text-center p-2 mt-16 flex flex-col`}
          >
            <span>ข้อมูลติดต่อ</span>
            <div className="flex flex-col md:flex-row md:gap-2">
              <span>Email : info@thaimediafund-thinktank2.com</span>
              <span>โทร : 099-019-9360 คุณคิส</span>
            </div>
          </div>
        }
      />
    );
  } else if (state === STATE_CONSTANT_REGISTER.PREVIEW) {
    return (
      <Container
        height="auto"
        // height="540px"
        // width="450px"
        // width={size?.isMobile ? "375px" : "450px"}
        state={state}
        header={
          <div className="relative ">
            <div
              className={`${
                size?.width > 2200 ? " mx-40" : "mx-2 md:mx-16 "
              } absolute bg-leoreal-blue w-80 h-auto  -my-8 md:-my-9 rounded-md`}
            >
              <div className="font-PSL242pro text-white text-center text-2xl ">
                <div>กรุณาตรวจสอบข้อมูลของคุณให้ถูกต้อง</div>
                <div>ก่อนกดปุ่มยืนยัน</div>
              </div>
            </div>
          </div>
        }
        body={
          <div>
            <div
              className=" my-12 space-y-5 w-auto h-auto  rounded-md border border-leoreal-bordertwo 
            -mx-1 md:mx-auto grid  bg-white"
            >
              <Controllers
                loadData={form}
                fields={[
                  {
                    type: "none",
                    classNameCore:
                      "col-span-1 my-auto  text-base  w-full mt-4  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4 ">
                          คำนำหน้าชื่อ
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {prefixName.label}
                        </div>
                      );
                    },
                  },

                  {
                    type: "none",
                    classNameCore:
                      "col-span-1 my-auto  text-base  w-full mt-4  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4 ">
                          ชื่อ
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {firstName}
                        </div>
                      );
                    },
                  },
                  // {
                  //   type: "customs",
                  //   classNameCore: "col-span-2",
                  //   name: "firstName",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                  //         {value}
                  //       </div>
                  //     );
                  //   },
                  // },

                  // {
                  //   classNameCore: "col-span-2  ",
                  //   type: "text",
                  //   name: "firstName",
                  //   placeholder: "ชื่อ",
                  //   label: "",
                  //   classNameLabel: "",
                  //   disabled: true,
                  //   classNameInput:
                  //     "font-PSL242pro text-leoreal-blue text-xl bg-white w-full  px-4 my-auto ",
                  // },
                  {
                    type: "none",
                    classNameCore: "col-span-1 my-auto  text-base  w-full  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4 ">
                          นามสกุล
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {lastName}
                        </div>
                      );
                    },
                  },
                  // {
                  //   classNameCore: "col-span-2  ",
                  //   type: "text",
                  //   disabled: true,

                  //   name: "lastName",
                  //   placeholder: "สกุล",
                  //   classNameInput:
                  //     "font-PSL242pro text-leoreal-blue text-xl bg-white  w-full  px-4",
                  // },
                  // {
                  //   type: "customs",
                  //   classNameCore: "col-span-2",
                  //   name: "lastName",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <div className="break-words font-PSL242pro text-leoreal-blue text-xl bg-white w-52 md:w-64   px-4 my-auto ">
                  //         {value}
                  //       </div>
                  //     );
                  //   },
                  // },

                  {
                    type: "none",
                    classNameCore: "col-span-1 my-auto  text-base  w-full  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4  ">
                          เบอร์โทร
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {phoneNumber}
                        </div>
                      );
                    },
                  },
                  // {
                  //   classNameCore: "col-span-2  ",
                  //   type: "text",
                  //   disabled: true,

                  //   name: "phoneNumber",
                  //   placeholder: "เบอร์โทร",
                  //   classNameInput:
                  //     "font-PSL242pro text-leoreal-blue text-xl bg-white  w-full  px-4",
                  // },
                  // {
                  //   type: "customs",
                  //   classNameCore: "col-span-2",
                  //   name: "phoneNumber",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <div className="break-words font-PSL242pro text-leoreal-blue text-xl bg-white w-52 md:w-64   px-4 my-auto  ">
                  //         {value}
                  //       </div>
                  //     );
                  //   },
                  // },

                  {
                    type: "none",
                    classNameCore: "col-span-1 my-auto  text-base  w-full  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4  ">
                          อีเมล
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {email}
                        </div>
                      );
                    },
                  },
                  // {
                  //   classNameCore: "col-span-2  ",
                  //   type: "text",
                  //   disabled: true,

                  //   name: "email",
                  //   placeholder: "อีเมล",
                  //   classNameInput:
                  //     "font-PSL242pro text-leoreal-blue text-xl bg-white  w-full h-10 px-4",
                  // },
                  // {
                  //   type: "customs",
                  //   classNameCore: "col-span-2",
                  //   name: "email",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <div className="break-words font-PSL242pro text-leoreal-blue text-xl bg-white w-52 md:w-64   px-4 my-auto  ">
                  //         {value}
                  //       </div>
                  //     );
                  //   },
                  // },

                  {
                    type: "none",
                    classNameCore: "col-span-1 my-auto  text-base  w-full  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-32  px-4 ">
                          ประเภทหน่วยงาน
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {typeAgency.label}
                          {typeAgency?.value === "other_agency" ? (
                            <div>- {otherAgencyName}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    },
                  },

                  {
                    type: "none",
                    classNameCore: "col-span-1 my-auto  text-base  w-full  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-32  px-4 ">
                          ชื่อหน่วยงาน
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {agency}
                        </div>
                      );
                    },
                  },
                  // {
                  //   classNameCore: "col-span-2  ",
                  //   type: "text",
                  //   disabled: true,

                  //   name: "agency",
                  //   placeholder: "หน่วยงาน/สังกัด",
                  //   classNameInput:
                  //     "font-PSL242pro text-leoreal-blue text-xl bg-white  w-full  px-4 ",
                  // },
                  // {
                  //   type: "customs",
                  //   classNameCore: "col-span-2",
                  //   name: "agency",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <div className="break-words font-PSL242pro text-leoreal-blue text-xl bg-white w-52 md:w-64   px-4 my-auto   ">
                  //         {value}
                  //       </div>
                  //     );
                  //   },
                  // },

                  {
                    type: "none",
                    classNameCore: "col-span-1 my-auto  text-base  w-full  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-32  px-4 ">
                          ตำแหน่ง
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {jobPosition}
                        </div>
                      );
                    },
                  },
                  // {
                  //   type: "customs",
                  //   classNameCore: "col-span-2",
                  //   name: "jobPosition",
                  //   fieldCustoms: ({ value }) => {
                  //     return (
                  //       <div className="break-words font-PSL242pro text-leoreal-blue text-xl bg-white w-52 md:w-64   px-4 my-auto   ">
                  //         {value}
                  //       </div>
                  //     );
                  //   },
                  // },

                  {
                    type: "none",
                    classNameCore:
                      "col-span-1 my-auto  text-base  w-full mt-4  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4 ">
                          อาหาร
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {/* {food.islam && <div>A</div>} */}

                          {Object.keys(food).every((key) => !food[key])
                            ? "-"
                            : Object.keys(food)?.map((key) => {
                                if (!food[key]) return null;
                                return (
                                  <div>
                                    {
                                      FOOD_LIST.find(
                                        (foodItem) => foodItem.value === key
                                      ).label
                                    }
                                    {key === "other_food" ? (
                                      <div>- {otherFoodName}</div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                        </div>
                      );
                    },
                  },

                  {
                    type: "none",
                    classNameCore:
                      "col-span-1 my-auto  text-base  w-full mt-4  ",
                    render: () => {
                      return (
                        <div className="font-PSL242pro text-leoreal-text text-xl  w-full  px-4 ">
                          วันที่ต้องการเข้าร่วมงาน
                        </div>
                      );
                    },
                  },
                  {
                    type: "none",
                    classNameCore: "col-span-2",
                    render: () => {
                      return (
                        <div className="break-words font-PSL242pro text-leoreal-blue  text-xl bg-white w-52 md:w-64  px-4  my-auto ">
                          {Object.keys(dateToJoin)?.map((key) => {
                            if (!dateToJoin[key]) return null;
                            return (
                              <div>
                                {
                                  DATE_TO_JOIN_LIST.find(
                                    (dateToJoinItem) =>
                                      dateToJoinItem.value === key
                                  ).label
                                }
                              </div>
                            );
                          })}
                        </div>
                      );
                    },
                  },

                  // {
                  //   type: "none",
                  //   classNameCore: "col-span-3",
                  //   render: () => {
                  //     return (
                  //       <div
                  //         clas
                  //         onClick={() => {
                  //           setState(STATE_CONSTANT_REGISTER.CURRENT);
                  //         }}
                  //         className="font-PSL242pro text-leoreal-text text-2xl text-center  px-3 flex justify-center mx-auto  mt-4 pt-10 cursor-pointer
                  //  "
                  //       >
                  //         แก้ไข
                  //       </div>
                  //     );
                  //   },
                  // },
                ]}
                // onSubmit={(e) => {
                //   // setState(STATE_CONSTANT_REGISTER.QR);

                //   if (!loading) {
                //     onClickSubmit(e);
                //   }
                // }}
                // classNameSubmit="  hidden "
                classNameWrapper="col-span-3"
              />

              {/* รอรับส่ง */}

              <div className="hidden">
                <QRCode value={id} renderAs="canvas" />
              </div>
            </div>

            <Controllers
              showError={false}
              showSymbol={false}
              fields={[
                {
                  classNameCore: "col-span-4 ",
                  type: "checkbox",
                  name: "agreement",
                  type: "none",
                  classNameWrapper: "space-x-2 ",
                  classNameOption: "text-xs ",
                  direction: "horizontal",
                  render: () => {
                    return (
                      <div className="w-full bg-white border border-x-leoreal-bordertwo rounded-md h-28 overflow-y-scroll text-base p-2 font-PSL242pro -mt-4">
                        <RenderModalPDPA params={params} id={id} />
                      </div>
                    );
                  },
                },

                // {
                //   classNameCore: "col-span-4 ",
                //   type: "checkbox",
                //   name: "agreement",
                //   // label: "Join Room",
                //   required: true,
                //   direction: "horizontal",
                //   options: [<RenderModalPDPA params={params} id={id} />],
                //   classNameWrapper: "space-x-2 ",
                //   classNameOption: "text-xs ",
                // },
              ]}
              onSubmit={(e) => {
                // console.log("1", e);
                if (agreement && !loading) {
                  onClickSubmit(form);
                } else {
                  alert("โปรดตกลงและยินยอมตามข้อกำหนดและเงื่อนไขของกิจกรรมนี้");
                }
              }}
              classNameSubmit="  hidden "
            />

            <div className="flex items-center mb-5">
              <input
                onChange={(e) => setAgreeMent(e.target.checked)}
                id="agreement"
                name="agreement"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label
                htmlFor="agreement"
                className="ml-2 text-leoreal-blue text-base md:text-lg  font-PSL242pro -mt-1"
              >
                ข้าพเจ้าตกลงและยินยอมตามข้อกำหนดและเงื่อนไขของกิจกรรมนี้
              </label>
            </div>

            <div className="font-PSL242pro text-red-600 text-xl text-center  px-2 flex justify-center mx-auto">
              <div className="flex flex-col items-center">
                <span>
                  สำหรับผู้ที่ลงทะเบียน และได้รับสิทธิ์ในการเข้าร่วมงานเสวนา
                </span>
                <span>
                  จะได้รับ QR Code ยืนยันสิทธิ์ทางอีเมลตามที่ลงทะเบียนไว้
                </span>
                <span>ภายใน 7 วันทำการ (ที่นั่งมีจำนวนจำกัด)</span>
              </div>
            </div>

            <ButtonSubmitController
              classNameSubmit="mx-auto flex justify-center  my-2 cursor-pointer"
              labelSubmit={
                <div
                  className={classNames(
                    "font-PSL242pro text-white text-2xl text-center  px-3 flex justify-center mx-auto   w-24 h-8 rounded-md ",
                    {
                      "bg-gray-400 cursor-not-allowed": loading,
                      "bg-leoreal-blue  cursor-pointer": !loading,
                    }
                  )}
                >
                  ยืนยัน
                </div>
              }
            />

            <div
              onClick={() => {
                setState(STATE_CONSTANT_REGISTER.CURRENT);
              }}
              // className="font-PSL242pro text-red-600 text-2xl text-center  px-3 flex justify-center mx-auto my-5  cursor-pointer"
              className="font-PSL242pro text-white text-2xl text-center  px-3 flex justify-center mx-auto my-5 w-24 h-8 rounded-md bg-leoreal-blue  cursor-pointer"
            >
              แก้ไขข้อมูล
            </div>
          </div>
        }
      />
    );
  } else if (state === STATE_CONSTANT_REGISTER.QR) {
    return (
      <Finish params={params} size={size} id={id} dateToJoin={dateToJoin} />
    );
  } else {
    return <React.Fragment />;
  }
};
export default RegisterMain;

// {next ? (
// <div className="bg-leoreal-blue w-72 h-auto mx-auto -my-5 rounded-md">
//   <div className="text-white text-center text-base  p-2">
//     กรุณาตรวจสอบข้อมูลของคุณให้ถูกต้อง
//     <div>ก่อนกดปุ่มยืนยัน</div>
//   </div>
// </div>
// ) : (
// <div className="bg-leoreal-blue w-48 h-11 mx-auto -my-5 rounded-md">
//   <div className="text-white text-center text-base pt-2 p-2">
//     ลงทะเบียนเพื่อเข้าร่วมงาน
//   </div>
// </div>
// )}

// {state === STATE_CONSTANT_REGISTER.PREVIEW ? (
//   <div className="my-4">
//     <div className="text-leoreal-text text-base text-center cursor-pointer mb-2">
//       แก้ไข
//     </div>
//     <div
//       onClick={() => {
//         setState(STATE_CONSTANT_REGISTER.QR);
//       }}
//       className="w-24 h-7 bg-leoreal-blue rounded-md text-white text-base text-center cursor-pointer mx-auto"
//     >
//       ยืนยัน
//     </div>
//   </div>
// ) : (
//   <div
//     onClick={() => {
//       setState(STATE_CONSTANT_REGISTER.PREVIEW);
//     }}
//     className="w-24 h-7 bg-leoreal-blue rounded-md   mx-auto mt-12 mb-6 cursor-pointer"
//   >
// <div className="text-white text-sm text-center mt-1 px-3">
//   ถัดไป
// </div>
//   </div>
// )}
